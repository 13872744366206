<template>
  <div class="container global-list-main-container">
    <div class="upper-global-title">
      <span class=""> Anticipos sin cuenta </span>
    </div>
    <div class="upper-global-hint">
      <b-icon icon="lightbulb-outline" type="is-primary"> </b-icon>
      <span class="global-form-hint-color"> Recuerda: </span> {{ hint }}
    </div>
    <b-tabs type="is-boxed">
      <b-tab-item
        label="Anticipos sin cuenta por pagar"
        icon="currency-usd-off"
      >
        <AllAdvancedPaymentsProviders />
      </b-tab-item>
      <b-tab-item label="Anticipos sin cuenta por cobrar" icon="currency-usd">
        <AllAdvancedPaymentsClients />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import AllAdvancedPaymentsClients from "../components/AllAdvancedPaymentsClients";
import AllAdvancedPaymentsProviders from "../components/AllAdvancedPaymentsProviders";
// @ is an alias to /src
export default {
  name: "AdvancedPayments",
  components: {
    AllAdvancedPaymentsClients,
    AllAdvancedPaymentsProviders,
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("CHANGEACTIVE", "advancedPayments");
  },
  async created() {
    let allPromises = [];

    allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
    allPromises.push(this.$store.dispatch("GETBANKS"));
    if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
    }
    if (this.$store.getters.PAYMENTTYPES.length === 0) {
      allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
    }
    allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));

    await Promise.all(allPromises);
  },
  methods: {},
  computed: {
    hint() {
      return this.$store.getters.HINT;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

/* eslint-disable vue/no-use-v-if-with-v-for */
<template>
  <div class="container global-list-main-container">
    <b-field grouped group-multiline class="global-lists-controls">
      <div class="control">
        <b-tooltip
          v-if="clients.length === 0"
          label="Primero debes agregar clientes al sistema o esperar a que el sistema cargue estos datos"
          multilined
        >
          <b-button type="is-primary" disabled
            >Agregar anticipo sin cuenta por cobrar</b-button
          >
        </b-tooltip>
        <b-button
          type="is-primary"
          @click="addAdvancedPayment()"
          v-if="clients.length > 0"
          >Agregar anticipo sin cuenta por cobrar</b-button
        >
      </div>
      <b-button
        type="is-primary"
        @click="removeThresholdMissingAccounts()"
        v-if="
          companyPaymentPlanModules &&
          companyPaymentPlanModules.treasury.removeMissingPaymentsClients ===
            'S' &&
          permissions &&
          permissions.treasury.removeMissingPaymentsClients === 'S'
        "
        >Eliminar saldos menores</b-button
      >
    </b-field>

    <div class="global-lists-search">
      <div class="global-lists-filters-container">
        <b-field label="Filtrar por clientes" label-position="inside">
          <b-select
            placeholder="Filtrar por clientes"
            icon="account-group-outline"
            v-model="filterClient"
            class="global-lists-filters-control"
          >
            <option value="">Todos los clientes</option>
            <option
              v-for="client in clients"
              :value="client"
              :key="client.NOMBRE_EMPRESA"
            >
              {{ client.NOMBRE_EMPRESA }}
            </option>
          </b-select>
        </b-field>
      </div>
    </div>

    <div class="table-header">
      Cantidad de anticipos sin cuenta por cobrar:
      <b>{{ filteredAdvancedPayments.length }}</b>
    </div>
    <div class="global-list-main-container">
      <b-table
        pagination-position="both"
        :data="filteredAdvancedPayments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="AdvancedPayments"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        :opened-detailed="defaultOpenedDetails"
        :selected.sync="selected"
        @details-open="
          (row) =>
            $buefy.toast.open({
              message: `Información de ${row.NUM_FOLIO}`,
              type: 'is-success',
              position: 'is-bottom',
            })
        "
        detailed
        detail-key="_id"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">
            No hay anticipos sin cuenta por cobrar
          </div>
        </template>

        <template slot-scope="props">
          <b-table-column
            label="Folio de pago"
            field="NUM_FOLIO"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus"
            field="STATUS_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus de facturación"
            field="STATUS_FAC_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FAC_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FAC_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Cliente"
            field="CLAVE_CLIENTE.NOMBRE_EMPRESA"
            sortable
            searchable
          >
            <span
              v-if="!checkIfValid(props.row.CLAVE_CLIENTE.NOMBRE_EMPRESA)"
              >{{ "Sin información" }}</span
            >
            <span>{{ props.row.CLAVE_CLIENTE.NOMBRE_EMPRESA }}</span>
          </b-table-column>

          <b-table-column
            label="Vendedor"
            field="sellerFormatted"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.sellerFormatted)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.sellerFormatted }}</span>
          </b-table-column>

          <b-table-column
            label="Forma de pago"
            field="FORMA_P.DESCRIBE_P"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.FORMA_P.DESCRIBE_P)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.FORMA_P.DESCRIBE_P }}</span>
          </b-table-column>

          <b-table-column
            label="Referencia de movimineto"
            field="MOVIMIENTO_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.MOVIMIENTO_FOR)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.MOVIMIENTO_FOR)">{{
              props.row.MOVIMIENTO_FOR
            }}</span>
          </b-table-column>

          <b-table-column
            label="Referencia"
            field="REFERENCIA"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.REFERENCIA }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad del pago"
            field="PAGO_PARC_FOR"
            sortable
            searchable
            :custom-sort="sortyByPaymentQuantity"
          >
            <span v-if="!checkIfValid(props.row.PAGO_PARC_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.PAGO_PARC_FOR | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column
            label="Saldo restante"
            field="RESTA"
            searchable
            sortable
          >
            <span v-if="!checkIfValid(props.row.RESTA)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.RESTA)">{{
              props.row.RESTA | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column
            label="Fecha de pago"
            field="formatedDate"
            sortable
            searchable
            :custom-sort="sortByDate"
          >
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formatedDate }}</span>
          </b-table-column>
        </template>

        <template slot="detail" slot-scope="props">
          <article class="media">
            <figure class="media-left">
              <p class="image is-64x64"></p>
            </figure>
            <div class="content">
              <div class="controls">
                <b-button
                  type="is-primary"
                  @click="showPaymentDetails(props.row)"
                  v-if="
                    props.row.STATUS_FAC !== 'SF' || props.row.STATUS === 'CA'
                  "
                  >Ver anticipo sin cuenta por cobrar</b-button
                >
                <b-button
                  type="is-primary"
                  @click="showEditMissingQuantity(props.row)"
                  >Editar saldo restante</b-button
                >
                <b-button
                  type="is-primary"
                  @click="showPaymentDetails(props.row)"
                  v-if="
                    props.row.STATUS_FAC === 'SF' && props.row.STATUS !== 'CA'
                  "
                  >Ver o timbrar anticipo sin cuenta por cobrar</b-button
                >

                <b-button
                  v-if="
                    props.row.STATUS !== 'CA' && props.row.STATUS_FAC === 'TI'
                  "
                  type="is-primary"
                  @click="cancelPaymentCFDI(props.row)"
                  >Cancelar anticipo sin cuenta por cobrar</b-button
                >

                <b-button
                  v-if="
                    props.row.STATUS !== 'CA' && props.row.STATUS_FAC !== 'TI'
                  "
                  type="is-primary"
                  @click="
                    startCancelEvent(
                      'danger',
                      function () {
                        cancelPayment(props.row);
                      },
                      'cancelar anticipo sin cuenta por cobrar'
                    )
                  "
                  >Cancelar anticipo sin cuenta por cobrar</b-button
                >

                <b-button
                  type="is-primary"
                  @click="showAcuseXML(props.row._id)"
                  v-if="props.row.STATUS_FAC === 'CA'"
                  >Ver acuse de cancelación</b-button
                >

                <b-button
                  type="is-primary"
                  @click="showPaymentXML(props.row._id)"
                  v-if="props.row.STATUS_FAC === 'TI'"
                  >Ver XML</b-button
                >

                <b-button
                  type="is-primary"
                  @click="downloadPaymentPDF(props.row._id)"
                  v-if="props.row.STATUS_FAC === 'TI'"
                  >Descargar PDF</b-button
                >

                <b-button
                  type="is-primary"
                  @click="openSendCFDI(props.row._id)"
                  v-if="props.row.STATUS_FAC === 'TI'"
                  >Enviar CFDI y XML</b-button
                >
              </div>
            </div>
          </article>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import Cleave from "cleave.js";
import Masks from "../../../data/cleave-masks";
import AddNewAdvancedPaymentModal from "./AddNewAdvancedPaymentModal";
import EditAdvancedPaymentMissingModal from "./EditAdvancedPaymentMissingModal";
import RemoveMissingPayments from "./RemoveMissingPayments";
import XMLViewer from "../../Accounting/components/XMLViewer.vue";
import SendCFDIModal from "../../Accounting/components/SendCFDIModal";
import AdvancedPaymentCancellation from "../../Accounting/components/AdvancedPaymentCancellation";
import { openConfirmationModalDanger } from "@/utils/component-fns";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "AllAdvancedPaymentsClients",
  props: [],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeValue: this.type,
      perPage: 50,
      selected: {},
      currentPage: 1,
      defaultOpenedDetails: [],
      masks: Masks,
      filterClient: "",
    };
  },
  async created() {
    moment.locale("es");
  },
  methods: {
    async reloadInformation() {
      let allPromises = [];

      allPromises.push(this.$store.dispatch("GETADVANCEDPAYMENTS"));
      allPromises.push(this.$store.dispatch("GETWAREHOUSESNOTRANSFERS"));
      allPromises.push(this.$store.dispatch("GETPROVIDERS"));
      allPromises.push(this.$store.dispatch("GETBANKS"));
      if (this.$store.getters.BANKMOVEMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETBANKMOVEMENTTYPES"));
      }
      allPromises.push(this.$store.dispatch("GETCLIENTS"));
      if (this.$store.getters.PAYMENTTYPES.length === 0) {
        allPromises.push(this.$store.dispatch("GETPAYMENTTYPES"));
      }
      allPromises.push(this.$store.dispatch("GETBANKACOUNTS"));
      allPromises.push(this.$store.dispatch("GETEMPLOYEES"));

      await Promise.all(allPromises);
    },
    addAdvancedPayment() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewAdvancedPaymentModal,
        props: {
          type: 1,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showPaymentDetails(paymentInformation) {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AddNewAdvancedPaymentModal,
        props: {
          type: 1,
          objectInformation: paymentInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    showEditMissingQuantity(paymentInformation) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: EditAdvancedPaymentMissingModal,
        props: {
          paymentInformation,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(a.FECHA_PAGO).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(b.FECHA_PAGO).getTime() || -Infinity)
        );
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param dangerMessage the message to display to the user
     */
    startCancelEvent(type, functionOnConfirm, dangerMessage) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalDanger.call(
        this,
        type,
        functionOnConfirm,
        dangerMessage
      );
    },
    async cancelPayment(payment) {
      try {
        let response = await this.$store.dispatch("CANCELADVANCEDPAYMENT", {
          paymentId: payment._id,
        });
        if (response == "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se canceló exitosamente el ancitipo sin cuenta por cobrar",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar el ancitipo sin cuenta por cobrar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar el ancitipo sin cuenta por cobrar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async downloadPaymentPDF(paymentId) {
      try {
        let response = await this.$store.dispatch("GETPAYMENTPDF", {
          paymentId: paymentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el pdf`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        const linkSource = `data:application/pdf;base64,${response.pdfData}`;
        const downloadLink = document.createElement("a");
        const fileName = response.pdfName;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el pdf`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async showPaymentXML(paymentId) {
      try {
        let response = await this.$store.dispatch("GETPAYMENTXML", {
          paymentId: paymentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el xml`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el xml`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    async cancelPaymentCFDI(payment) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: AdvancedPaymentCancellation,
        props: {
          paymentId: payment._id,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    async showAcuseXML(paymentId) {
      try {
        let response = await this.$store.dispatch("GETACUSEPAYMENTXML", {
          paymentId: paymentId,
        });
        if (!response.success) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: `Hubo un error al descargar el acuse del pago`,
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        this.$buefy.modal.open({
          canCancel: ["x"],
          parent: this,
          component: XMLViewer,
          props: {
            xmlData: response.xmlData,
            xmlName: response.xmlName,
          },
          hasModalCard: false,
          customClass: "primary-modal-class",
          trapFocus: true,
          fullScreen: false,
          destroyOnHide: true,
        });
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: `Hubo un error al descargar el acuse del pago`,
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    openSendCFDI(paymentId) {
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: SendCFDIModal,
        props: {
          paymentId: paymentId,
          action: "SENDCFDIPAYMENT",
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
    sortyByPaymentQuantity(a, b, isAsc) {
      if (isAsc) {
        return a.PAGO_PARC_FOR.localeCompare(b.PAGO_PARC_FOR, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.PAGO_PARC_FOR.localeCompare(a.PAGO_PARC_FOR, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    removeThresholdMissingAccounts() {
      this.reloadInformation();
      this.$buefy.modal.open({
        canCancel: ["x"],
        parent: this,
        component: RemoveMissingPayments,
        props: {
          paymentType: 1,
        },
        hasModalCard: false,
        customClass: "primary-modal-class",
        trapFocus: true,
        fullScreen: false,
        destroyOnHide: true,
      });
    },
  },
  watch: {
    selected(val) {
      if (val != null) {
        if (this.defaultOpenedDetails.includes(val.id)) {
          const index = this.defaultOpenedDetails.indexOf(val.id);
          this.defaultOpenedDetails.splice(index, 1);
        } else {
          this.defaultOpenedDetails.push(val.id);
        }
      }
    },
    defaultOpenedDetails() {
      this.selected = null;
    },
  },
  computed: {
    warehouses() {
      return this.$store.getters.WAREHOUSES;
    },
    clients() {
      return this.$store.getters.CLIENTS.map((singleClient) => {
        const clientWithProps = { ...singleClient };
        return clientWithProps;
      }).sort((a, b) => {
        return a.NOMBRE_EMPRESA > b.NOMBRE_EMPRESA ? 1 : -1;
      });
    },
    advancedPayment() {
      return this.$store.getters.ADVANCEDPAYMENTS.map((singlePayment) => {
        const paymentWithProps = { ...singlePayment };

        paymentWithProps.PAGO_PARC_FOR = String(singlePayment.PAGO_PARC);

        paymentWithProps.sellerFormatted = "";
        if (paymentWithProps.VENDEDOR) {
          paymentWithProps.sellerFormatted = `${paymentWithProps.VENDEDOR.NOMBRE} ${paymentWithProps.VENDEDOR.PATERNO} ${paymentWithProps.VENDEDOR.MATERNO}`;
        }

        paymentWithProps.formatedDate = moment(
          paymentWithProps.FECHA_PAGO
        ).format("DD-MM-YYYY HH:mm");

        paymentWithProps.MOVIMIENTO_FOR = "";
        if (paymentWithProps.CLAVE_MOVIMIENTO) {
          paymentWithProps.MOVIMIENTO_FOR =
            paymentWithProps.CLAVE_MOVIMIENTO.REFERENCIA;
        }

        // Set status
        if (paymentWithProps.STATUS === "AC") {
          paymentWithProps.STATUS_FOR = "Activo";
        }

        if (paymentWithProps.STATUS === "CA") {
          paymentWithProps.STATUS_FOR = "Cancelado";
        }

        // Set invoice status
        if (paymentWithProps.STATUS_FAC === "SF") {
          paymentWithProps.STATUS_FAC_FOR = "Sin timbrar";
        }

        if (paymentWithProps.STATUS_FAC === "TI") {
          paymentWithProps.STATUS_FAC_FOR = "Timbrada";
        }

        return paymentWithProps;
      }).filter((singlePayment) => {
        return singlePayment.CLAVE_CLIENTE;
      });
    },
    filteredAdvancedPayments() {
      if (this.filterClient === "") {
        return this.advancedPayment.map((singlePayment) => {
          let singlePaymentWithProps = { ...singlePayment };
          return singlePaymentWithProps;
        });
      }
      return this.advancedPayment.filter((singlePayment) => {
        if (singlePayment.CLAVE_CLIENTE._id === this.filterClient._id) {
          return true;
        }
        return false;
      });
    },
    hint() {
      return this.$store.getters.HINT;
    },
    companyInformation() {
      return this.$store.getters.COMPANY_INFORMATION;
    },
    companyPaymentPlanModules() {
      if (
        this.$store.getters.COMPANY_INFORMATION &&
        this.$store.getters.COMPANY_INFORMATION.paymentPlan
      ) {
        return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
      }
      return this.$store.getters.COMPANY_INFORMATION.paymentPlan.modules;
    },
    permissions() {
      if (this.$store.getters.USER.permissions) {
        return this.$store.getters.USER.permissions.permissions;
      }
      return this.$store.getters.USER.permissions;
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

import { render, staticRenderFns } from "./AdvancedPayments.vue?vue&type=template&id=4047b578&scoped=true&"
import script from "./AdvancedPayments.vue?vue&type=script&lang=js&"
export * from "./AdvancedPayments.vue?vue&type=script&lang=js&"
import style0 from "./AdvancedPayments.vue?vue&type=style&index=0&id=4047b578&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4047b578",
  null
  
)

export default component.exports
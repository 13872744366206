<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Agregar movimiento manual</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Saldo restante (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': newAdvancedPaymentErrors.RESTA }"
        :message="{
          'El saldo restante no es válido': newAdvancedPaymentErrors.RESTA,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="2"
          placeholder="Ejemplo: 123"
          icon="currency-usd"
          :required="true"
          v-model="newAdvancedPayment.RESTA"
          :disabled="false"
        />
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button type="is-success" @click="editMissingQuantity()"
        >Editar saldo restante</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import moment from "moment";
// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

import Cleave from "cleave.js";

// @ is an alias to /src
export default {
  name: "EditAdvancedPaymentMissingModal",
  props: ["paymentInformation"],
  components: {},
  directives: { cleave },
  data() {
    return {
      typeSelected: [],
      newAdvancedPayment: {
        RESTA: 0,
      },
      newAdvancedPaymentErrors: {
        RESTA: false,
      },
      moment: moment,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc Edits remaining quantity of advanced payment
     */
    async editMissingQuantity() {
      try {
        this.movementRequiredErrors = validateFields(
          this.newAdvancedPayment,
          this.newAdvancedPaymentErrors
        );

        if (checkValidationErrors(this.newAdvancedPaymentErrors)) {
          let response = await this.$store.dispatch(
            "EDITADVANCEDPAYMENTMISSINGQUANTITY",
            {
              advancedPayment: {
                _id: this.paymentInformation._id,
                RESTA: this.newAdvancedPayment.RESTA,
              },
            }
          );

          if (response === "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se editó exitosamente el saldo restante del anticipo",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al editar el saldo restante del anticipo en la base de datos",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al editar el saldo restante del anticipo en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al editar el saldo restante del anticipo en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
  },
  computed: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

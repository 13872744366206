<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Eliminar saldos menores</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado </span>
    </div>

    <h3 class="form-division">Información general</h3>

    <b-field grouped class="special-margin">
      <b-field
        label="Cantidad de los saldos menores a eliminar (Requerido)"
        expanded
        label-position="inside"
        :type="{ 'is-danger': missingAccountsRequiredErrors.CANTIDAD }"
        :message="{
          'La cantidad de los saldos menores a eliminar no es valida':
            missingAccountsRequiredErrors.CANTIDAD,
        }"
      >
        <MoneyInput
          currency="MXN"
          :digits="5"
          placeholder="Ejemplo: 1000"
          :required="true"
          v-model="missingAccounts.CANTIDAD"
          icon="cash"
        >
        </MoneyInput>
      </b-field>
    </b-field>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="
          startConfirmationEvent(
            'success',
            function () {
              removeCurrentMissingAccounts();
            },
            'remover saldos menores'
          )
        "
        >Remover saldos menores</b-button
      >
      <b-button type="is-danger" @click="closeModal()">Regresar</b-button>
    </div>

    <div class="global-list-main-container" v-if="paymentType === 0">
      <b-table
        pagination-position="both"
        :data="filteredPayments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Accounts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay pagos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            label="Folio de pago"
            field="NUM_FOLIO"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus"
            field="STATUS_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Proveedor"
            field="CLAVE_PRV.NOMBRE_PRV"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.CLAVE_PRV.NOMBRE_PRV)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.CLAVE_PRV.NOMBRE_PRV }}</span>
          </b-table-column>

          <b-table-column
            label="Forma de pago"
            field="FORMA_P.DESCRIBE_P"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.FORMA_P.DESCRIBE_P)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.FORMA_P.DESCRIBE_P }}</span>
          </b-table-column>

          <b-table-column
            label="Referencia"
            field="REFERENCIA"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.REFERENCIA }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad del pago"
            field="PAGO_PARC_FOR"
            sortable
            searchable
            :custom-sort="sortyByPaymentQuantity"
          >
            <span v-if="!checkIfValid(props.row.PAGO_PARC_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.PAGO_PARC_FOR | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column
            label="Saldo restante"
            field="RESTA"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.RESTA)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.RESTA)">{{
              props.row.RESTA | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column
            label="Fecha de pago"
            field="formatedDate"
            sortable
            searchable
            :custom-sort="sortByDate"
          >
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formatedDate }}</span>
          </b-table-column>
        </template>

        <template #footer>
          <div class="has-text-right">
            Total en pagos a eliminar saldos menores:
            {{ getTableTotalValues(0) | money("MXN", 2) }}
          </div>
        </template>
      </b-table>
    </div>

    <div class="global-list-main-container" v-if="paymentType === 1">
      <b-table
        pagination-position="both"
        :data="filteredPayments"
        :striped="true"
        :bordered="true"
        :hoverable="true"
        ref="Accounts"
        paginated
        :current-page="currentPage"
        :per-page="perPage"
        sort-icon="arrow-up"
        sort-icon-size="is-small"
        default-sort-direction="desc"
        checkbox-position="left"
        scrollable
        default-sort="NUM_FOLIO"
      >
        <template #empty>
          <div class="has-text-centered">No hay pagos</div>
        </template>

        <template slot-scope="props">
          <b-table-column
            label="Folio de pago"
            field="NUM_FOLIO"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.NUM_FOLIO)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.NUM_FOLIO }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus"
            field="STATUS_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Estatus de facturación"
            field="STATUS_FAC_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.STATUS_FAC_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.STATUS_FAC_FOR }}</span>
          </b-table-column>

          <b-table-column
            label="Cliente"
            field="CLAVE_CLIENTE.NOMBRE_EMPRESA"
            sortable
            searchable
          >
            <span
              v-if="!checkIfValid(props.row.CLAVE_CLIENTE.NOMBRE_EMPRESA)"
              >{{ "Sin información" }}</span
            >
            <span>{{ props.row.CLAVE_CLIENTE.NOMBRE_EMPRESA }}</span>
          </b-table-column>

          <b-table-column
            label="Vendedor"
            field="sellerFormatted"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.sellerFormatted)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.sellerFormatted }}</span>
          </b-table-column>

          <b-table-column
            label="Forma de pago"
            field="FORMA_P.DESCRIBE_P"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.FORMA_P.DESCRIBE_P)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.FORMA_P.DESCRIBE_P }}</span>
          </b-table-column>

          <b-table-column
            label="Referencia de movimineto"
            field="MOVIMIENTO_FOR"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.MOVIMIENTO_FOR)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.MOVIMIENTO_FOR)">{{
              props.row.MOVIMIENTO_FOR
            }}</span>
          </b-table-column>

          <b-table-column
            label="Referencia"
            field="REFERENCIA"
            sortable
            searchable
          >
            <span v-if="!checkIfValid(props.row.REFERENCIA)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.REFERENCIA }}</span>
          </b-table-column>

          <b-table-column
            label="Cantidad del pago"
            field="PAGO_PARC_FOR"
            sortable
            searchable
            :custom-sort="sortyByPaymentQuantity"
          >
            <span v-if="!checkIfValid(props.row.PAGO_PARC_FOR)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.PAGO_PARC_FOR | money("MXN", 2) }}</span>
          </b-table-column>

          <b-table-column
            label="Saldo restante"
            field="RESTA"
            searchable
            sortable
          >
            <span v-if="!checkIfValid(props.row.RESTA)">{{
              "Sin información"
            }}</span>
            <span v-if="checkIfValid(props.row.RESTA)">{{
              props.row.RESTA | money("MXN", 2)
            }}</span>
          </b-table-column>

          <b-table-column
            label="Fecha de pago"
            field="formatedDate"
            sortable
            searchable
            :custom-sort="sortByDate"
          >
            <span v-if="!checkIfValid(props.row.formatedDate)">{{
              "Sin información"
            }}</span>
            <span>{{ props.row.formatedDate }}</span>
          </b-table-column>
        </template>

        <template #footer>
          <div class="has-text-right">
            Total en pagos a eliminar saldos menores:
            {{ getTableTotalValues(0) | money("MXN", 2) }}
          </div>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { validateFields, checkValidationErrors } from "../../../utils/fns";
import { openConfirmationModalSucces } from "../../../utils/component-fns";
import Cleave from "cleave.js";
import moment from "moment";

// Cleave variable initialization to bind formats to inputs
const cleave = {
  name: "cleave",
  bind(el, binding) {
    const input = el.querySelector("input");
    input._vCleave = new Cleave(input, binding.value);
  },
  unbind(el) {
    const input = el.querySelector("input");
    input._vCleave.destroy();
  },
};

// @ is an alias to /src
export default {
  name: "RemoveMissingPayments",
  props: ["paymentType"],
  components: {},
  directives: { cleave },
  data() {
    return {
      perPage: 50,
      currentPage: 1,
      missingAccounts: {
        CANTIDAD: "",
      },
      missingAccountsRequiredErrors: {
        CANTIDAD: false,
      },
      moment: moment,
    };
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    /**
     * @desc saves a new brand in the database, display messages according to the status
     */
    async removeCurrentMissingAccounts() {
      try {
        this.missingAccountsRequiredErrors = validateFields(
          this.missingAccounts,
          this.missingAccountsRequiredErrors
        );

        if (checkValidationErrors(this.missingAccountsRequiredErrors)) {
          if (!this.checkQuantityMaxValue()) {
            this.$buefy.dialog.confirm({
              title: "Error",
              message:
                "Hubo un error al eliminar los saldos menores, el saldo máximo a eliminar es $10.00",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
            return;
          }

          let response = await this.$store.dispatch(
            this.paymentType === 0
              ? "REMOVEMISSINGPAYMENTSPROVIDERS"
              : "REMOVEMISSINGPAYMENTSCLIENTS",
            {
              type: this.paymentType,
              missingAccounts: this.missingAccounts,
            }
          );
          if (response == "Success") {
            this.$buefy.dialog.confirm({
              title: "Éxito",
              message: "Se eliminaron los saldos menores exitosamente",
              confirmText: "Entendido",
              type: "is-success",
              hasIcon: true,
              onConfirm: () => {
                this.closeModal();
              },
              cancelText: "cancelar",
              canCancel: false,
            });
          } else {
            this.$buefy.dialog.confirm({
              title: "Error",
              message: "Hubo un error al eliminar los saldos menores",
              confirmText: "Entendido",
              type: "is-danger",
              hasIcon: true,
              cancelText: "cancelar",
              canCancel: false,
            });
          }
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message: "Hubo un error al eliminar los saldos menores",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message: "Hubo un error al eliminar los saldos menores",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startConfirmationEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
    getTableTotalValues(type) {
      let sum = 0;

      sum = this.filteredPayments.reduce(
        (acc, o) => acc + parseInt(o.RESTA),
        0
      );

      return sum;
    },
    checkQuantityMaxValue() {
      return this.missingAccounts.CANTIDAD <= 10;
    },
    sortyByPaymentQuantity(a, b, isAsc) {
      if (isAsc) {
        return a.PAGO_PARC_FOR.localeCompare(b.PAGO_PARC_FOR, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      } else {
        return b.PAGO_PARC_FOR.localeCompare(a.PAGO_PARC_FOR, undefined, {
          numeric: true,
          sensitivity: "base",
        });
      }
    },
    sortByDate(a, b, isAsc) {
      if (isAsc) {
        return (
          (new Date(b.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(a.FECHA_PAGO).getTime() || -Infinity)
        );
      } else {
        return (
          (new Date(a.FECHA_PAGO).getTime() || -Infinity) -
          (new Date(b.FECHA_PAGO).getTime() || -Infinity)
        );
      }
    },
  },
  computed: {
    filteredPayments() {
      return this.$store.getters.ADVANCEDPAYMENTS.map((singlePayment) => {
        const paymentWithProps = { ...singlePayment };

        paymentWithProps.PAGO_PARC_FOR = String(singlePayment.PAGO_PARC);

        paymentWithProps.formatedDate = moment(
          paymentWithProps.FECHA_PAGO
        ).format("DD-MM-YYYY HH:mm");

        // Set status
        if (paymentWithProps.STATUS === "AC") {
          paymentWithProps.STATUS_FOR = "Activo";
        }

        if (paymentWithProps.STATUS === "CA") {
          paymentWithProps.STATUS_FOR = "Cancelado";
        }

        // Set invoice status
        if (paymentWithProps.STATUS_FAC === "SF") {
          paymentWithProps.STATUS_FAC_FOR = "Sin timbrar";
        }

        if (paymentWithProps.STATUS_FAC === "TI") {
          paymentWithProps.STATUS_FAC_FOR = "Timbrada";
        }

        return paymentWithProps;
      }).filter((singlePayment) => {
        if (this.paymentType === 0) {
          return (
            this.missingAccounts.CANTIDAD > 0 &&
            singlePayment.RESTA < this.missingAccounts.CANTIDAD &&
            singlePayment.RESTA > -this.missingAccounts.CANTIDAD &&
            singlePayment.RESTA !== 0 &&
            singlePayment.STATUS !== "CA" &&
            singlePayment.CLAVE_PRV
          );
        }

        if (this.paymentType === 1) {
          return (
            this.missingAccounts.CANTIDAD > 0 &&
            singlePayment.RESTA <= this.missingAccounts.CANTIDAD &&
            singlePayment.RESTA >= -this.missingAccounts.CANTIDAD &&
            singlePayment.RESTA !== 0 &&
            singlePayment.STATUS !== "CA" &&
            singlePayment.CLAVE_CLIENTE
          );
        }

        return false;
      });
    },
  },
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
</style>

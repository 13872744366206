<template>
  <div class="container add-global-modal-main-container">
    <h1 class="add-global-modal-main-title">Cancelación de CFDI de anticipo</h1>

    <div class="global-form-hint-container">
      <p class="global-form-hint">Los campos requeridos tienen el marco</p>
      <span class="global-form-hint-color"> Morado</span>. Al hacer click en
      "Cancelar nota de crédito" se realiza la solicitud de cancelación para ver
      el estatus de cancelación es necesario conectar su cuenta del SAT en la
      sección de "Documentos SAT"
    </div>

    <div id="belvo"></div>

    <div class="manual-bank-account">
      <h3 class="form-division">Información general</h3>

      <b-field grouped class="special-margin required-input">
        <b-field
          label="Motivo de cancelación (Requerido)"
          expanded
          label-position="inside"
          :type="{ 'is-danger': newCancellationRequiredErrors.RAZON_CAN }"
          :message="{
            'El motivo no es válido': newCancellationRequiredErrors.RAZON_CAN,
          }"
        >
          <b-select
            placeholder="Motivo de cancelación"
            v-model="newCancellation.RAZON_CAN"
            required
            expanded
          >
            <option value="" disabled>
              Selecciona un motivo de cancelación
            </option>
            <option
              v-for="cancellationReason in cfdiCancellationReasons"
              :value="cancellationReason.CLAVE"
              :key="cancellationReason.CLAVE"
            >
              {{ cancellationReason.DESCRIPCION }}
            </option>
          </b-select>
        </b-field>
      </b-field>
    </div>

    <div class="add-global-controls">
      <b-button
        type="is-success"
        @click="
          startConfirmationEvent(
            'success',
            function () {
              cancelCFDI();
            },
            'cancelar cfdi'
          )
        "
        >Cancelar CFDI de pago</b-button
      >
      <b-button type="is-danger" @click="closeModal()" class="cancel-button"
        >Regresar</b-button
      >
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from "moment";
import { checkValidationErrors, validateFields } from "../../../utils/fns";
import CfdiCancellationReasons from "../../../data/cfdi-cancellation-reasons";
import { openConfirmationModalSucces } from "../../../utils/component-fns";
export default {
  name: "AdvancedPaymentCancellation",
  props: ["paymentId"],
  components: {},
  data() {
    return {
      moment: moment,
      newCancellation: {
        RAZON_CAN: "",
      },
      newCancellationRequiredErrors: {
        RAZON_CAN: false,
      },
      cfdiCancellationReasons: CfdiCancellationReasons,
    };
  },
  async created() {
    moment.locale("es");
  },
  mounted() {},
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async cancelCFDI() {
      try {
        this.newCancellationRequiredErrors = validateFields(
          this.newCancellation,
          this.newCancellationRequiredErrors
        );

        if (!checkValidationErrors(this.newCancellationRequiredErrors)) {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al crear la solicitud de cancelación en la base de datos revise los campos obligatorios",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
          return;
        }
        let response = await this.$store.dispatch("CANCELADVANCEDPAYMENT", {
          paymentId: this.paymentId,
          cancellationReason: this.newCancellation.RAZON_CAN,
        });
        if (response === "Success") {
          this.$buefy.dialog.confirm({
            title: "Éxito",
            message:
              "Se canceló exitosamente el ancitipo sin cuenta por cobrar",
            confirmText: "Entendido",
            type: "is-success",
            hasIcon: true,
            onConfirm: () => {
              this.closeModal();
            },
            cancelText: "cancelar",
            canCancel: false,
          });
        } else {
          this.$buefy.dialog.confirm({
            title: "Error",
            message:
              "Hubo un error al cancelar el ancitipo sin cuenta por cobrar en la base de datos",
            confirmText: "Entendido",
            type: "is-danger",
            hasIcon: true,
            cancelText: "cancelar",
            canCancel: false,
          });
        }
      } catch (error) {
        this.$buefy.dialog.confirm({
          title: "Error",
          message:
            "Hubo un error al cancelar el ancitipo sin cuenta por cobrar en la base de datos",
          confirmText: "Entendido",
          type: "is-danger",
          hasIcon: true,
          cancelText: "cancelar",
          canCancel: false,
        });
      }
    },
    /**
     *
     * @desc calls external function to open action confirmation modal
     * @param type the type of the dialog
     * @param functionOnConfirm the function tht will be run after the confirmation success
     * @param name the name of the object we want to delete
     * @param deleteMessage the delete message that the user will see
     */
    startConfirmationEvent(type, functionOnConfirm, name) {
      // Call external function that opens confirmation dialog and pass the context to the function
      openConfirmationModalSucces.call(this, type, functionOnConfirm, name);
    },
  },
  computed: {},
  watch: {},
};
</script>

<style scoped>
@import "../../Global/style/Global.css";
@import "../../Purchases/style/AddPurchaseOrder.css";
</style>
